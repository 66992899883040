import {mapGetters, mapMutations} from "vuex";
import helpers from "../assets/js/helpers";

export default {
    computed: {
        ...mapGetters({
            loading: "getLoading",
            notificacao: "getNotificacao",
            usuarioLogado: "getUsuarioLogado",
            usuarios: "getUsuarios",
            empresas: "getEmpresas",
            pacotes: "getPacotes",
            clientes: "getClientes",
            contratos: "getContratos",
        }),
        permissaoMaster() {
            return this?.usuarioLogado?.master ?? false;
        },
        permissaoAdmin() {
            return this?.usuarioLogado?.admin ?? false;
        },
        empresaLogada() {
            return this?.empresas?.find(empresa => Number(empresa.id) === Number(this.usuarioLogado.empresaId)) ?? {};
        }
    },
    methods: {
        ...mapMutations({
            setLoading: "SET_LOADING",
            setNotificacao: "SET_NOTIFICACAO",
            resetState: "RESET_STATE"
        }),
        ativarLoading() {
            this.setLoading(true);
        },
        desativarLoading() {
            this.setLoading(false);
        },
        navegar(nomeRota, params = {}) {
            helpers.navegar(nomeRota, params);
        },
        interpretarErro(error, mensagemPadrao = "Falha no processamento!") {
            let response = helpers.isset(() => error.response) ? error.response : false;
            if (!response) {
                this.setNotificacao({
                    status: true,
                    mensagem: mensagemPadrao,
                    categoria: 503
                });
                return;
            }

            let categoria = response.status;
            let mensagem = response.data.mensagem ?? mensagemPadrao;

            if (response?.data?.erros) {
                Object.keys(response.data.erros).forEach(key => {
                    mensagem = response.data.erros[key][0];
                });
            }
            this.setNotificacao({status: true, mensagem, categoria});

            if (Number(categoria) === 401) {
                this.resetState();
                this.navegar("login");
            }
        },
        buscarEmpresa(idBusca) {
            return this.empresas.find(el => Number(el.id) === Number(idBusca));
        },
        obterCodigoRequisicao(error) {
            let response = helpers.isset(() => error.response) ? error.response : false;

            if (!response) return 503;
            return Number(response.status);
        },
        notificacaoSucesso(mensagem) {
            this.setNotificacao({
                status: true,
                mensagem,
                categoria: 200
            });
        },
        notificacaoErro(mensagem, categoria = 500) {
            this.setNotificacao({
                status: true,
                mensagem,
                categoria
            });
        },
        traduzirStatusCliente(status) {
            switch (status) {
                case 1:
                    return "Ativo";
                case 2:
                    return "Inativo";
                default:
                    return "Inativo";
            }
        },
        traduzirPagarme(status) {
            switch (status) {
                case "processing":
                    return "Aguardando Pagamento";
                case "authorized":
                    return "Aguardando Pagamento";
                case "paid":
                    return "Pago com Sucesso";
                case "refunded":
                    return "Reembolsado";
                case "waiting_payment":
                    return "Aguardando Pagamento";
                case "pending_refund":
                    return "Aguardando Reembolso";
                case "refused":
                    return "Pagamento Recusado";
                case "chargedback":
                    return "Pagamento Estornado";
                case "aguardando_assinatura_pagarme":
                    return "Aguardando Pagamento do Pacote";
                case "active":
                    return "Aguardando Pagamento";
                default:
                    return "Sem Pagamento";
            }
        },
        traduzirClicksign(status) {
            if (status === "enviado") return "Enviado";
            else if (status === "assinado") return "Assinado";
            else if (status === "cancelado") return "Cancelado";
            else if (status === "expirado") return "Expirado";
            else return "Não iniciado";
        },
        setColorChip(id) {
            if (id == 1) {
                return "grey darken-2";
            }
            if (id == 2) {
                return "green darken-1";
            }
            if (id == 3) {
                return "red darken-1";
            }
        },
        setColorStatus(id) {
            if (id == 1) {
                return "grey darken-2";
            }
            if (id == 2) {
                return "orange darken-2";
            }
            if (id == 3) {
                return "blue darken-1";
            }
            if (id == 4) {
                return "red darken-1";
            }
            if (id == 5) {
                return "green darken-1";
            }
        },
    }
};
