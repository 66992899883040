<template>
	<v-alert border="top" prominent type="error" class="py-6" icon="mdi-close-octagon">
		<slot />
	</v-alert>
</template>

<script>
export default {
	name: "BlocoErro",
};
</script>

<style scoped>

</style>