import {backend, multipart} from "./backend";
import store from "../store/index";
import helpers from "../assets/js/helpers";

const listar = async () => {
    let uri = `api/reanalise`;
    store.commit('SET_LOADING', true)
    let response = await backend.get(uri, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};

const buscar = async (id) => {
    store.commit('SET_LOADING', true)
    let uri = `api/reanalise/${id}`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};

const buscarPorCliente = async (cliente_id) => {
    store.commit('SET_LOADING', true)
    let uri = `api/reanalise/cliente/${cliente_id}`;
    let response = await backend.get(uri, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};

const cadastrar = async (form) => {

    const formData = new FormData();
    formData.append('cliente_id', form.cliente_id)
    formData.append('observacao_cliente', form.observacao_cliente)
    form.documentos.forEach((el, index) => {
        formData.append("documentos[]", el)
    })


    store.commit('SET_LOADING', true)
    let uri = "api/reanalise";
    let response = await multipart.post(uri, formData, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
};

const editar = async (id, dados) => {
    let uri = `api/reanalise/${id}`;
    let response = await backend.put(uri, dados, helpers.montarHeaderToken());
    return response;
};

export default {
    listar,
    editar,
    cadastrar,
    buscar,
    buscarPorCliente
};