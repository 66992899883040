<template>
	<div class="font-size-26 font-weight-medium titulo-pagina">
		<slot />
	</div>
</template>

<script>
export default {
	name: "TituloPagina",
};
</script>

<style scoped>
.titulo-pagina {
    color: var(--cor-titulos-1);
    display: inline-block;
    padding: 15px 20px 2px 5px;
    /* border-radius: 5px; */
    /*border-bottom: 2px solid var(--cor-primaria-100);*/
}
</style>