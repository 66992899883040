import {backend, multipart} from "./backend";
import store from "../store/index";
import helpers from "../assets/js/helpers";

const obterCards = async () => {
    let uri = `api/dashboard/cards`;
    store.commit('SET_LOADING', true)
    let response = await backend.get(uri, helpers.montarHeaderToken());
    store.commit('SET_LOADING', false)
    return response;
}

export default {
    obterCards
}