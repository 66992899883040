<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Dashboard</titulo-pagina>
          <div class="ml-1 page-subtitle">Dados dos últimos 30 dias</div>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <DashboardSimpleCard v-for="card in cards" :card="card" :key="'dashboard-card-'+card.name" />
      </v-row>
      <v-row class="bg-image" align="center">
        <v-col cols="12">
          <v-img :src="logotipo" contain max-width="500" class="mx-auto opacity"/>
        </v-col>
      </v-row>
    </v-container>
  </menu-principal>
</template>

<script>
import MenuPrincipal from "../../components/MenuPrincipal";
import DashboardSimpleCard from "@/components/DashboardSimpleCard.vue";
import TituloPagina from "@/components/TituloPagina.vue";
import BlocoErro from "@/components/BlocoErro.vue";
import reanalise from "@/services/reanalise";
import dashboard from "@/services/dashboard";

export default {
  name: "Inicio",
  mixins: [],
  components: {
    TituloPagina,
    DashboardSimpleCard,
    MenuPrincipal,
    BlocoErro,
  },
  data() {
    return {
      cards: []
    };
  },
  computed: {
    permissaoPagina() {
      return this.permissaoMaster;
    },
    logotipo() {
      return require("../../assets/imgs/logo_redondo.png");
    },
  },
  methods: {
    async obterCards() {
      await dashboard.obterCards().then(resp => {
        if (resp.status === 200 && Array.isArray(resp.data)) {
          this.cards = resp.data;
        }
      });
    },
  },
  watch: {},
  mounted() {
    this.obterCards();
  },
  created() {
  },
};
</script>

<style scoped>
.opacity {
  opacity: 0.03 !important;
}

.page-subtitle {
  padding-left: 2px;
  color: #999999;
}

.bg-image{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0;
}
</style>
