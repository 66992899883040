<template>
  <v-app class="pl-lg-3">

    <v-app-bar app clipped-left flat color="white" class="custom-app-bar">

      <v-app-bar-nav-icon v-if="!drawer" @click="drawer = !drawer" color="var(--cor-primaria-100)"/>

      <v-app-bar-title class="hidden-sm-and-down" v-else>
        <v-img :src="logotipo" max-height="40px" max-width="160px"
               class="cursor-pointer" @click="navegar('inicio')"
        ></v-img>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-avatar
              v-bind="attrs" v-on="on"
              class="white--text"
              color="orange"
              size="40"
          >{{ nomeUsuario | duasPrimeirasLetras }}
          </v-avatar>

        </template>
        <v-list class="py-0">
          <v-list-item dense link @click="editarSenha()">
            <v-list-item-title>Alterar Senha</v-list-item-title>
          </v-list-item>

          <v-list-item dense link @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
        app floating clipped color="white"
        class="rounded-lg custom-navigation-drawer ml-3"
        v-model="drawer">

      <v-list class="px-3">
        <v-list-item
            v-for="item in links"
            :key="item.title"
            link
            class="rounded-lg mt-2"
            :class="{'fundo-ativo': verificarRotaAtiva(item)}"
            @click="navegar(item.destino)"
        >
          <v-list-item-icon>
            <v-icon
                class="rounded-circle"
                :class="{'fundo-ativo': verificarRotaAtiva(item)}">
              {{ item.icone }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.titulo }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:prepend>
        <v-list rounded class="pa-3 d-flex d-lg-none">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <v-img
                    :src="logotipo"
                    max-height="40px"
                    max-width="160px"
                    class="cursor-pointer"
                    @click="navegar('inicio')"
                ></v-img>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template v-slot:append>
        <v-list class="pa-3">
          <v-list-item dense link @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app mdi-flip-h</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-main>
      <slot/>
    </v-main>
  </v-app>
</template>

<script>
import mixinGeral from "../mixin/geral";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "MenuPrincipal",
  mixins: [mixinGeral],
  components: {},
  data() {
    return {
      drawer: null,
      colapsed: false,
    };
  },
  computed: {
    ...mapGetters({usuarioLogado: "getUsuarioLogado"}),
    links() {
      const usuario = this.usuarioLogado;

      if (!usuario) return null;

      const menu = [
        {
          titulo: "Clientes",
          icone: "mdi-account-group-outline",
          ordemExibicao: 3,
          destino: "clientesIndex",
          subdestinos: ["clientesIndex", "clientesCadastrar"]
        },{
          titulo: "Representantes",
          icone: "mdi-account-tie",
          ordemExibicao: 3,
          destino: "representantesIndex",
          subdestinos: ["representantesIndex", "representantesCadastrar"]
        },{
          titulo: "Pacotes",
          icone: "mdi-package-variant",
          ordemExibicao: 4,
          destino: "pacotesIndex",
          subdestinos: ["pacotesIndex", "pacotesCadastrar"]
        }, {
          titulo: "Inadimplências",
          icone: "mdi-clipboard-text-search",
          ordemExibicao: 5,
          destino: "acompanhamentosIndex",
          subdestinos: ["acompanhamentosIndex"]
        }, {
          titulo: "Comissões",
          icone: "mdi-account-cash",
          ordemExibicao: 6,
          destino: "comissaoIndex",
          subdestinos: ["comissaoIndex"]
        }
      ];

      if (this.permissaoMaster) {
        menu.push(
            {
              titulo: "Dashboard",
              icone: "mdi-view-dashboard-outline",
              ordemExibicao: 0,
              destino: "inicio",
              subdestinos: ["inicio"]
            },
            {
              titulo: "Empresas",
              icone: "mdi-domain",
              ordemExibicao: 2,
              destino: "empresasIndex",
              subdestinos: ["empresasIndex", "empresasCadastrar"]
            }, {
              titulo: "Reanalisar Cadastro",
              icone: "mdi-gavel",
              ordemExibicao: 6,
              destino: "reanaliseIndex",
              subdestinos: ["reanaliseIndex"]
            });
      }

      if (this.permissaoMaster || this.permissaoAdmin) {
        menu.push({
          titulo: "Usuários",
          icone: "mdi-shield-account",
          ordemExibicao: 1,
          destino: "usuariosIndex",
          subdestinos: ["usuariosIndex", "usuariosCadastrar"]
        });
      }

      return menu.sort((a, b) => Number(a.ordemExibicao) - Number(b.ordemExibicao));
    },
    logotipo() {
      return require("../assets/imgs/logo_retangulo_menor.png");
    },
    nomeUsuario() {
      const usuario = this.usuarioLogado;

      if (!usuario) return null;

      const nome = usuario.nome || "";

      if (nome.length <= 20) return nome;
      return nome.substring(0, 17) + "...";
    },
    idUsuario() {
      const usuario = this.usuarioLogado;

      if (!usuario) return null;

      return usuario.id;
    },
  },
  methods: {
    ...mapActions(["actionLogout"]),
    verificarRotaAtiva(menu) {
      return menu.subdestinos.includes(this.$route.name);
    },
    editarSenha() {
      this.navegar("usuariosAlterarSenha", {
        id: this.idUsuario,
      });
    },
    async logout() {
      this.ativarLoading();
      try {
        await this.actionLogout();
        this.navegar("login");
      } catch (e) {
        this.interpretarErro(e);
      } finally {
        this.desativarLoading();
      }
    },
  },
  watch: {},
  created() {
  },
};
</script>

<style scoped>
.fundo-ativo {
  background-color: var(--cor-primaria-100);
  color: white !important;
}

.custom-navigation-drawer {
  max-height: calc(100% - 94px) !important;
  margin-top: 15px !important;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, .06) !important;
//background-color: transparent!important;
}

.custom-navigation-drawer.v-navigation-drawer--is-mobile {
  margin-left: 0 !important;
  padding-left: 0 !important;
  max-height: 100% !important;
  margin-top: 0 !important;
  border-radius: 0 !important;
}

.custom-app-bar {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .06) !important;
}
</style>
