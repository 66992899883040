<template>
  <v-col cols="12" md="4" lg="3" xl="2">
    <v-card>
      <v-card-subtitle class="card-title">{{ card.name }}</v-card-subtitle>
      <v-card-text class="text-h3">
        <v-row>
          <v-col cols="8">
            {{ card.value }}
          </v-col>
          <v-col cols="4" align="right" v-if="card.link && card.value > 0">
            <v-btn icon class="mt-5 float-end" style="z-index: 30000" color="orange" :to="card.link+'/'" dark small>
              <v-icon>mdi-share-circle</v-icon>
            </v-btn>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: "DashboardSimpleCard",
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.card-title {
  font-size: 1.1rem;
  font-weight: normal;
}
</style>